import { DeviceOwner, DeviceRecipient } from "../app/dashboard/DeviceList";
import { getTranslatedCountry, TranslatedCountry } from "./getCountry";

export interface ModalUser {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  externalUserId?: string;
  streetAddress?: string;
  postcode?: string;
  country?: TranslatedCountry;
  trackingLink?: string;
}

export const getFormattedUser = (
  user: DeviceRecipient | DeviceOwner | undefined
): ModalUser => {
  let firstName: string | undefined = undefined;
  let lastName: string | undefined = undefined;
  let emailAddress: string | undefined = undefined;
  let phoneNumber: string | undefined = undefined;
  let externalUserId: string | undefined = undefined;
  let trackingLink: string | undefined = undefined;
  let streetAddress: string | undefined = undefined;
  let postcode: string | undefined = undefined;
  let country: TranslatedCountry = TranslatedCountry.UNKNOWN;

  if (user == null) {
    return {
      country,
      emailAddress,
      externalUserId,
      firstName,
      lastName,
      phoneNumber,
      postcode,
      streetAddress,
      trackingLink,
    };
  }

  const {
    firstName: name,
    lastName: familyName,
    phoneNumber: phone,
    emailAddress: email,
    externalUserId: userId,
    postalAddress,
  } = user;
  firstName = name;
  lastName = familyName;
  if (phone) {
    phoneNumber = phone;
  }
  if (email) {
    emailAddress = email;
  }
  if (userId) {
    externalUserId = userId;
  }
  if (postalAddress?.streetAddress) {
    streetAddress = postalAddress?.streetAddress;
  }
  if (postalAddress?.postcode) {
    postcode = postalAddress?.postcode;
  }
  if (postalAddress?.country) {
    country = getTranslatedCountry(postalAddress?.country);
  }

  if (Object.prototype.hasOwnProperty.call(user, "trackingLink")) {
    const { trackingLink: link } = user as DeviceRecipient;
    if (link) {
      trackingLink = link;
    }
    return {
      country,
      emailAddress,
      externalUserId,
      firstName,
      lastName,
      phoneNumber,
      postcode,
      streetAddress,
      trackingLink,
    };
  }
  return {
    country,
    emailAddress,
    externalUserId,
    firstName,
    lastName,
    phoneNumber,
    postcode,
    streetAddress,
  };
};
