import { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import {
  EditModal,
  IconButton,
  IconButtonSize,
  IconType,
} from "../../../components";
import { Modal, ModalProps } from "../../../components/modal";
import { IntermediaryId, PermissionScope } from "../Dashboard";
import { ShippedDevice } from "../DeviceList";
import { SignalField, TimeField } from "../ColoredFields";
import { DeviceModalColumn, RowContent } from "./DeviceModalColumn";
import { intermediaryIdToIntermediaryName } from "../../../domain/intermediary";
import { theme } from "../../../theme";
import { getFormattedUser, getFormattedSubscriptionType } from "../../../utils";
import {
  EditModalState,
  EditModalStatus,
} from "../../../components/ConfirmOrCancel";
import { EditDeviceOwnerColumn } from "./EditDeviceOwnerColumn";
import { ActivationRowControl } from "../ActivationRowControl";
import { setIsOnlineStatus } from "../../../utils/setIsOnlineStatus";
import { useMutation } from "@apollo/client";
import {
  DeleteDeviceNameDocument,
  UpdateDeviceNameDocument,
} from "generated/graphql/graphql";

const DeviceModalHeader = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;

  .deviceId {
    font-size: 22px;
  }

  .type {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;
    border-radius: 50%;

    background: ${(props) => props.theme.colors.white[500]};
    font-size: 16px;
    color: ${(props) => props.theme.colors.blue[500]};
  }
`;

const DeviceModalContent = styled.div`
  color: ${(props) => props.theme.colors.black[500]};
  text-color: ${(props) => props.theme.colors.black[500]};
  display: grid;

  height: 100%;
  width: 100%;

  @media (max-width: 480px) {
    grid-template-rows: repeat(4, auto);
    row-gap: 1rem;
  }

  @media (min-width: 480px) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
  }
`;

const DeviceNameCell = styled.div<{ centered?: boolean }>`
  position: relative;
  padding: 0 8px;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: bold;
  width: 100%;
  color: ${(props) => props.theme.colors.black[500]};
`;

const DeviceName = styled.div`
  font-weight: bold;
  width: 100%;
`;

const UpdateStatus = styled.div<ProgressBarProps>`
  background: linear-gradient(
    to right,
    #d3d3d3 0%,
    #1abc9c ${({ percentage }) => percentage}%,
    #a8e6cf ${({ percentage }) => percentage}%
  );
  text-color: ${(props) => props.theme.colors.black[500]};
  padding: 8px;
  border-radius: ${({ theme }): string => theme.borderRadius.small};
  display: flex;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface ProgressBarProps {
  percentage: number;
}

const SCOPE_UPDATE_INTERMEDIARY_DEVICE_NAMES =
  "update_intermediary_device_names";

const SCOPE_UPDATE_INTERMEDIARY_DEVICE_OWNER =
  "update_intermediary_device_owner";

interface DeviceModalProps extends ModalProps {
  device?: ShippedDevice;
  scopesByIntermediary: Map<IntermediaryId, PermissionScope[]>;
}

export const DeviceModal = ({
  device,
  scopesByIntermediary,
  open,
  onClose,
}: DeviceModalProps): JSX.Element => {
  if (!device) return <></>;

  const [deviceName, setDeviceName] = useState(device.name);

  const {
    activation,
    shipmentStatus,
    deviceId,
    firmwareVersion,
    intermediaryId,
    isUpdating,
    lastMessageAt,
    lastSeenAt,
    ownedBy,
    publishInterval,
    recentMeter,
    shippedAt,
    shippedTo,
    signalStrength,
    subscription,
    type,
    updateStatus,
  } = device;

  const [owner, setOwner] = useState(getFormattedUser(ownedBy));

  const [state, setState] = useState<EditModalState>({
    status: EditModalStatus.CLOSED,
  });

  const {
    firstName: recipientFirstName,
    lastName: recipientLastName,
    phoneNumber: recipientPhoneNumber,
    emailAddress: recipientEmailAddress,
    externalUserId: recipientExternalUserId,
    trackingLink,
    streetAddress: recipientStreetAddress,
    postcode: recipientPostcode,
    country: recipientCountry,
  } = getFormattedUser(shippedTo);

  const scopesForIntermediary: string[] = intermediaryId
    ? scopesByIntermediary.get(intermediaryId) ?? []
    : [];

  const OwnerRows: RowContent[] = [
    { content: owner.firstName, title: "Fornavn" },
    { content: owner.lastName, title: "Etternavn" },
    { content: owner.externalUserId, title: "Kundenr" },
    {
      content:
        owner.phoneNumber != null ? (
          <a href={`tel:${owner.phoneNumber}`}>{owner.phoneNumber}</a>
        ) : undefined,
      title: "Telefonnr",
    },
    {
      content:
        owner.emailAddress != null ? (
          <a href={`mailto:${owner.emailAddress}`}>{owner.emailAddress}</a>
        ) : undefined,
      title: "E-post",
    },
    { content: owner.streetAddress, title: "Gateadresse" },
    { content: owner.postcode, title: "Postnummer" },
    { content: owner.country, title: "Land" },
  ];

  const RecipientRows: RowContent[] = [
    { content: recipientFirstName, title: "Fornavn" },
    { content: recipientLastName, title: "Etternavn" },
    { content: recipientExternalUserId, title: "Kundenr" },
    {
      content:
        recipientPhoneNumber != null ? (
          <a href={`tel:${recipientPhoneNumber}`}>{recipientPhoneNumber}</a>
        ) : undefined,
      title: "Telefonnr",
    },
    {
      content:
        recipientEmailAddress != null ? (
          <a href={`mailto:${recipientEmailAddress}`}>
            {recipientEmailAddress}
          </a>
        ) : undefined,
      title: "E-post",
    },
    { content: recipientStreetAddress, title: "Gateadresse" },
    { content: recipientPostcode, title: "Postnummer" },
    { content: recipientCountry, title: "Land" },
    { content: shippedAt?.toLocaleString(), title: "Sendt ut" },
  ];

  const DeviceDetailRows: RowContent[] = [
    {
      content: (
        <DeviceNameCell
          centered={
            !scopesForIntermediary.includes(
              SCOPE_UPDATE_INTERMEDIARY_DEVICE_NAMES
            )
          }
        >
          <DeviceName>{deviceName ?? "—"}</DeviceName>
          {scopesForIntermediary.includes(
            SCOPE_UPDATE_INTERMEDIARY_DEVICE_NAMES
          ) && (
            <EditDeviceNameIconButton
              deviceName={deviceName ?? ""}
              deviceId={deviceId}
              onUpdateDeviceName={setDeviceName}
            />
          )}
        </DeviceNameCell>
      ),
      title: "Enhetsnavn",
    },
    { content: recentMeter.meterId, title: "Målernummer" },
    {
      content: recentMeter.producer,
      title: "Målertype",
    },
    { content: shipmentStatus, title: "Status" },
    {
      content: trackingLink ? (
        <a target="_blank" rel="noopener noreferrer" href={trackingLink}>
          {trackingLink}
        </a>
      ) : undefined,
      title: "Sporing",
    },
    {
      content: activation ? (
        <ActivationRowControl
          initialStatus={activation?.status}
          deviceId={deviceId}
        />
      ) : (
        "❌"
      ),
      title: "Aktivering",
    },
    {
      content: getFormattedSubscriptionType(subscription.subscriptionType),
      title: "Abonnementstype",
    },
    {
      content: <div>Versjon {renderFirmwareVersion(firmwareVersion)}</div>,
      title: "Firmware",
    },
  ];

  if (isUpdating && updateStatus) {
    DeviceDetailRows.push({
      content: (
        <UpdateStatus percentage={updateStatus.progressPercent}>
          Oppdaterer → v. {updateStatus.version}.0
        </UpdateStatus>
      ),
      title: "",
    });
  }

  const ActivityLogRows: RowContent[] = [
    {
      content: (
        <TimeField
          time={lastSeenAt}
          showError={!lastSeenAt}
          greyError
          error={setIsOnlineStatus(
            shippedAt,
            lastSeenAt,
            lastMessageAt,
            activation?.status
          )}
        />
      ),
      title: "Siste aktivitet",
    },
    {
      content: (
        <TimeField
          time={lastMessageAt}
          showError={!lastMessageAt}
          error="Aldri"
          greyError={!lastMessageAt && !lastSeenAt}
        />
      ),
      title: "HAN-data?",
    },
    {
      content: <SignalField signal={signalStrength} type={type} />,
      title: "Signalstyrke",
    },
    {
      content: <PublishIntervalField publishInterval={publishInterval} />,
      title: "Sendeintervall",
    },
  ];

  return (
    <Modal
      open={open}
      header={
        <DeviceModalHeader>
          <div className="deviceId">
            {deviceName ? deviceId + " (" + deviceName + ")" : deviceId}
          </div>
          <div className="type">{type === "HAN_LTEM" ? "4G" : "Wifi"}</div>
          {intermediaryIdToIntermediaryName(intermediaryId)}
        </DeviceModalHeader>
      }
      onClose={onClose}
    >
      <DeviceModalContent>
        {state.status === EditModalStatus.CLOSED ? (
          <DeviceModalColumn
            header="Eier"
            rows={OwnerRows}
            editable={scopesForIntermediary.includes(
              SCOPE_UPDATE_INTERMEDIARY_DEVICE_OWNER
            )}
            onEdit={() => setState({ status: EditModalStatus.OPEN })}
          />
        ) : (
          <EditDeviceOwnerColumn
            ownedBy={owner}
            setOwnedBy={setOwner}
            deviceId={deviceId}
            isEditable={scopesForIntermediary.includes(
              SCOPE_UPDATE_INTERMEDIARY_DEVICE_OWNER
            )}
            state={state}
            setState={setState}
          />
        )}
        <DeviceModalColumn header="Mottaker" rows={RecipientRows} />
        <DeviceModalColumn header="Enhetsdetaljer" rows={DeviceDetailRows} />
        <DeviceModalColumn header="Aktivitet" rows={ActivityLogRows} />
      </DeviceModalContent>
    </Modal>
  );
};

const EditDeviceNameIconButton = ({
  deviceId,
  deviceName,
  onUpdateDeviceName,
}: {
  deviceId: string;
  deviceName: string;
  onUpdateDeviceName: (name: string) => void;
}): JSX.Element => {
  const [value, setValue] = useState(deviceName);
  const [state, setState] = useState<EditModalState>({
    status: EditModalStatus.CLOSED,
  });

  const [updateDeviceNameMutation] = useMutation(UpdateDeviceNameDocument);
  const [deleteDeviceNameMutation] = useMutation(DeleteDeviceNameDocument);

  useEffect(() => {
    setValue(deviceName);
  }, [deviceName]);

  const updateDeviceName = async (deviceName: string): Promise<void> => {
    setState({ status: EditModalStatus.SAVING });
    try {
      if (deviceName === "") {
        await deleteDeviceNameMutation({ variables: { deviceId } });
      } else {
        await updateDeviceNameMutation({ variables: { deviceId, deviceName } });
      }
      onUpdateDeviceName(deviceName);
      setState({ status: EditModalStatus.CLOSED });
    } catch (error) {
      if (error instanceof Error) {
        setState({ error: error.message, status: EditModalStatus.FAILED });
      } else {
        setState({ error: `${error}`, status: EditModalStatus.FAILED });
      }
    }
  };

  return (
    <>
      <EditModal
        closeModal={() => setState({ status: EditModalStatus.CLOSED })}
        value={value}
        onChange={(event: FormEvent<HTMLInputElement>) =>
          setValue(event.currentTarget.value)
        }
        onSave={() => updateDeviceName(value)}
        state={state}
      />
      <IconButton
        type={IconType.EDIT}
        onClick={() => setState({ status: EditModalStatus.OPEN })}
        size={IconButtonSize.SMALL}
        color={theme.colors.blue[500]}
      />
    </>
  );
};

const PublishIntervalField = ({
  publishInterval,
}: {
  publishInterval?: number;
}): JSX.Element => {
  if (publishInterval == null) {
    return <div>–</div>;
  }
  return <div>{publishInterval} sek</div>;
};

const renderFirmwareVersion = (firmwareVersion: number | undefined): string => {
  const minimumBetaVersion = 1000;
  const isBetaVersion = (firmwareVersion: number) =>
    firmwareVersion >= minimumBetaVersion;
  if (!firmwareVersion) {
    return "ukjent";
    // eslint-disable-next-line no-magic-numbers
  } else if (isBetaVersion(firmwareVersion)) {
    return `${(firmwareVersion / 100).toFixed(2).toString()}-beta`;
  } else {
    return firmwareVersion.toString() + ".0";
  }
};
